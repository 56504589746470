<template>
  <div class="p-2">
    <Form @loadInformeExecute="loadInformeExecute($event)" ref="form" />
    <div class="p-3 mt-2" v-if="data.length">
      <EjecutionTable :data="data" />
    </div>
    <div v-else>
      <div v-if="!isLoading">
        <div class="text-center">
          <img src="assets/img/empty.png" alt="Empty" />
          <p class="text-muted">No hay información para mostrar</p>
        </div>
      </div>
      <div v-else class="text-center">
        <img src="assets/img/loader.gif" alt="Loading..." />
      </div>
    </div>
    <div v-if="false">this is a little change</div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import Form from "./Form.vue";

import EjecutionTable from "./EjecutionTable.vue";

// const generarColorHexAleatorio = () => {
//   return `#${Math.floor(Math.random() * 0xffffff).toString(16)}`;
// };
export default {
  name: "informe-ejecucion-tab",
  components: {
    Form,
    EjecutionTable,
  },
  mounted() {
    this.getSectores();

    this.allSelected = true;
    this.allSelectedSector = true;
    this.checkedAll();

    this.loadInformeExecute(this.form);
  },
  data() {
    return {
      data: [],
      sectores: [],
      configSector: [],
      isLoading: false,
      form: {
        sectores: [],
        status: [],
        date_init: moment().format("YYYY-MM-01"),
        date_end: moment().format("YYYY-MM-DD"),
      },
      allSelected: false,
      allSelectedSector: false,
      statusList: [
        {
          id: 2,
          text: "Asignadas",
          class: "checkbox-primary",
        },
        {
          id: 3,
          text: "Descargadas en el movil",
          class: "checkbox-dark",
        },
        {
          id: 4,
          text: "En ejecución",
          class: "checkbox-info",
        },
        {
          id: 5,
          text: "Ejecutadas",
          class: "checkbox-success",
        },
        {
          id: 6,
          text: "Certificadas",
          class: "checkbox-warning",
        },
        {
          id: 8,
          text: "Rechazadas",
          class: "checkbox-danger",
        },
      ],
      chart: [],
      chart2: [],
    };
  },
  methods: {
    dateDifference(end, init) {
      if (end && init) {
        const dateEnd = moment(end);
        const dateInit = moment(init);
        if (dateEnd.isValid && dateInit.isValid) {
          const hours = moment.duration(dateInit.diff(dateEnd)).hours();
          const minutes = moment.duration(dateInit.diff(dateEnd)).minutes();
          const seconds = moment.duration(dateInit.diff(dateEnd)).seconds();
          return `${hours} H, ${minutes} Min. ${seconds} Seg.`;
        }
      }
    },
    async loadInformeExecute(form) {
      let cor = sessionStorage.cor;
      this.isLoading = true;
      this.data = [];
      this.chart = [];
      this.chart2 = [];

      try {
        this.$refs.form.isLoading = true;
        const response = await axios.post("/param-ots-report", {
          cor: cor.replaceAll("'", "").split(","),
          date_end: form.date_end + " 23:59:59",
          date_init: form.date_init + " 00:00:00",
          status: form.status,
          sectores: form.sectores,
        });
        const { data } = response;
        data.forEach((item) => {
          if (this.form.sectores.includes(item.sector)) {
            let msgTime = "";

            if (item.date_diff) {
              const status = item.fecha_resolucion
                ? "Completado"
                : "En proceso";
              msgTime = `tiempo: ${item.date_diff.days}d ${item.date_diff.h}h ${item.date_diff.i}min [${status}]`;
            }
            const main = item.principalInformation;
            if (main) {
              item.FAFR = this.dateDifference(
                item.fecha_asigna,
                main.fecha_recepcion
              );
              item.FRFAS = this.dateDifference(
                main.fecha_recepcion,
                main.fecha_arribo_sitio
              );
              item.FASFC = this.dateDifference(
                main.fecha_arribo_sitio,
                main.fecha_causa
              );
              item.FCFF = this.dateDifference(
                main.fecha_causa,
                main.fecha_finaliza
              );
              item.TT = this.dateDifference(
                item.fecha_asigna,
                main.fecha_finaliza
              );
            }
            this.data.push({
              ...item,
              msgTime,
            });
          }
        });


        const status = _.countBy(this.data, "estado_o_t_s.descripcion");

        for (const key in status) {
          this.chart.push({
            name: key,
            value: status[key],
            color: "#F00",
          });
        }
        this.$emit("getChart", this.chart);

        // new grafica for sector
        // console.log(this.data);
        const statusChart = this.data.map((item) => ({
          text: item.sector,
          desc: item.estado_o_t_s.descripcion,
          // date: moment(item.estado_o_t_s.updated_at).format("YYYY-MM-DD"),
          date: moment(this.reduceDateOt(item.estado_o_t_s.id_estado, item)).format("YYYY-MM-DD"),
        }));

        const group = _.groupBy(statusChart, "text");

        for (const key in group) {
          const desc = _.countBy(group[key], "desc");
          const asignado = desc["Asignado"] ? desc["Asignado"] : 0;
          const certificado = desc["Certificada"] ? desc["Certificada"] : 0;
          const ejecutado = desc["Ejecutada"] ? desc["Ejecutada"] : 0;
          const enejecucion = desc["En ejecución"] ? desc["En ejecución"] : 0;
          const rechazada = desc["Rechazada"] ? desc["Rechazada"] : 0;
          const Descargado = desc["Descargado"] ? desc["Descargado"] : 0;

          this.chart2.push({
            text: key,
            ...desc,
            total:
              asignado +
              ejecutado +
              certificado +
              enejecucion +
              rechazada +
              Descargado,
            parentID: "",
          });
        }

        let groupData = [];
        for (const key in group) {
          groupData = _.groupBy(group[key], "date");
          for (const keyDate in groupData) {
            const desc = _.countBy(groupData[keyDate], "desc");
            const asignado = desc["Asignado"] ? desc["Asignado"] : 0;
            const certificado = desc["Certificada"] ? desc["Certificada"] : 0;
            const ejecutado = desc["Ejecutada"] ? desc["Ejecutada"] : 0;
            const enejecucion = desc["En ejecución"] ? desc["En ejecución"] : 0;
            const rechazada = desc["Rechazada"] ? desc["Rechazada"] : 0;
            const Descargado = desc["Descargado"] ? desc["Descargado"] : 0;

            this.chart2.push({
              text: keyDate,
              ...desc,
              total:
                asignado +
                ejecutado +
                certificado +
                enejecucion +
                rechazada +
                Descargado,
              parentID: key,
            });
          }
        }

        this.$emit("getChart2", this.chart2);

        this.isLoading = false;
        this.$refs.form.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    searchRegisters() {
      this.loadInformeExecute(this.form);
    },
    getSectores() {
      this.sectores = JSON.parse(sessionStorage.getItem("sectores"));

      this.sectores.forEach((element) => {
        this.configSector.push({
          id: element,
          text: element,
          class: "checkbox-primary",
        });
      });
    },

    checkedAll() {
      for (let s in this.statusList) {
        this.form.status.push(this.statusList[s].id);
      }

      for (let s in this.configSector) {
        this.form.sectores.push(this.configSector[s].id);
      }
    },

    selectAll() {
      this.form.status = [];

      if (!this.allSelected) {
        for (let s in this.statusList) {
          this.form.status.push(this.statusList[s].id);
        }
      }
    },
    selectAllSector() {
      this.form.sectores = [];

      if (!this.allSelectedSector) {
        for (let s in this.configSector) {
          this.form.sectores.push(this.configSector[s].id);
        }
      }
    },

    select() {
      this.allSelected = false;
    },

    selectSector() {
      this.allSelectedSector = false;
    },
    reduceDateOt(status, item){
      /*
        par key: value
        2: Asignado,
        3: Descargada,
        4: En ejecucion 
        5: Ejecutada,
        6: Certificada,
        8: Rechazada,
      */

      switch (status) {
        case 2:
          return item.fecha_resolucion;
          
        case 3:          
        case 4:
           return item.updated_at;
          
        case 5:
          return item.fecha_asigna;
          
        case 6:
          return item.fecha_certificacion;
  
        case 8:
          return item.fecha_rechazada;
          
        default:
          return 'Fecha no manejada';
      }
    }
  },
};
</script>
