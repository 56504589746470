<template>
  <b-row>
    <b-col md="6">
      <div>
        <p class="text-muted">
          <b>D FA-FR:</b> Direfencia Fecha asignación - fecha de recepción
          <br />
          <b>D FR-FAS:</b> Direfencia Fecha recepción - fecha de arribo al sitio
          <br />
          <b>D FAS-FC:</b> Direfencia Fecha arribo al sitio - fecha localización
          de causa <br />
          <b>D FC-FF:</b> Direfencia Fecha localización de causa - fecha
          resolución
        </p>
      </div>
    </b-col>
    <b-col md="12">
      <DxDataGrid
        :data-source="data"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :columnAutoWidth="true"
        :allow-column-resizing="true"
        :allow-column-reordering="true"
        id="settlementTable"
        v-if="data.length"
        :height="580"
      >
        <DxExport :enabled="true" />
        <DxColumnChooser
          emptyPanelText="Arrastre una columna aquí para ocultar"
          :enabled="true"
          title="Arrastre columna aquí..."
        />
        <DxColumnFixing :enabled="true" />
        <DxGroupPanel
          :visible="true"
          emptyPanelText="Arrastre una columna aquí para agrupar"
        />
        <DxSearchPanel :visible="true" :width="240" placeholder="Buscar..." />
        <DxPaging :page-size="30" />
        <DxPager
          :visible="true"
          infoText="Página {0} de {1} ({2} registros)"
          :allowed-page-sizes="[5, 15, 30, 50]"
          display-mode="full"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
        />
        <DxHeaderFilter :visible="true" :allowSearch="true" />
        <DxGroupPanel :visible="true" />

        <DxColumn caption="sector" data-field="sector" />
        <DxColumn caption="OT" alignment="center" data-field="num_ot" />
        <DxColumn alignment="center">
          <DxColumn caption="Estado" data-field="estado_o_t_s.descripcion" />
          <DxColumn caption="Icono" alignment="center" cell-template="Icono" />
        </DxColumn>
        <template #Icono="{ data: cellData }">
          <i :class="cellData.data.estado_o_t_s.icono"></i>
        </template>
        <DxColumn
          caption="Brigada"
          alignment="center"
          data-field="user.nombre"
        />
        <DxColumn caption="Tiempos" alignment="center">
          <DxColumn caption="Fecha asigna" data-field="fecha_asigna" />
          <DxColumn
            caption="D FA - FR"
            data-field="FAFR"
            alignment="center"
            width="160"
          />
          <DxColumn
            caption="Fecha recepción"
            data-field="principalInformation.fecha_recepcion"
          />
          <DxColumn
            caption="D FR - FAS"
            data-field="FRFAS"
            alignment="center"
            width="160"
          />
          <DxColumn
            caption="Fecha arribo la sitio"
            data-field="principalInformation.fecha_arribo_sitio"
          />
          <DxColumn
            caption="D FAS - FC"
            data-field="FASFC"
            alignment="center"
            width="160"
          />
          <DxColumn
            caption="Fecha causa"
            data-field="principalInformation.fecha_causa"
          />
          <DxColumn
            caption="D FC - FF"
            data-field="FCFF"
            alignment="center"
            width="160"
          />
          <DxColumn
            caption="Fecha resolución"
            data-field="principalInformation.fecha_finaliza"
          />
        </DxColumn>

        <DxColumn caption="Tiempo" data-field="TT" alignment="center" />
        <DxColumn caption="Tipo Aviso" data-field="tipo_aviso" />
        <DxColumn
          caption="Cant. Reasignaciones"
          data-field="reasignaciones"
          alignment="center"
        />

        <DxMasterDetail :enabled="true" template="master-detail" />
        <template #master-detail="{ data }">
          <div>
            <OrderDetails :id-ot="data.data.id_ot" />
          </div>
        </template>

        <DxSummary>
          <DxGroupItem
            column="num_ot"
            summary-type="count"
            display-format="{0} trabajos"
            :align-by-column="true"
          />
        </DxSummary>
      </DxDataGrid>
    </b-col>
  </b-row>
</template>
<style>
.in-ejecution-order {
  background-color: #5d95a5 !important;
}
</style>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxGroupPanel,
  DxPager,
  DxPaging,
  DxSearchPanel,
  DxColumnFixing,
  DxHeaderFilter,
  DxColumnChooser,
  DxExport,
  DxSummary,
  DxGroupItem,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import OrderDetails from "@/components/admin/map/modal/OrderDetails.vue";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxGroupPanel,
    DxPager,
    DxPaging,
    DxSearchPanel,
    DxColumnFixing,
    DxHeaderFilter,
    DxColumnChooser,
    DxExport,
    DxSummary,
    DxGroupItem,
    DxMasterDetail,
    OrderDetails,
  },
};
</script>
