<template>
  <div class="p-3 mt-2 mb-2">
    <DxCheckBox v-model="show" :text="message" />
    <transition name="fade">
      <div v-if="show" class="mt-3">
        <transition name="slide-fade">
          <b-row md="12" class="justify-content-center">
            <b-col>
              <div class="form-group">
                <label>Fecha inicio:</label>
                <input
                  v-model="form.date_init"
                  :disabled="isLoading"
                  type="date"
                  class="form-control form-control-sm"
                />
              </div>
            </b-col>
            <b-col>
              <div class="form-group">
                <label>Fecha final:</label>
                <input
                  v-model="form.date_end"
                  :min="form.date_init"
                  type="date"
                  :disabled="isLoading"
                  class="form-control form-control-sm"
                />
              </div>
            </b-col>
          </b-row>
        </transition>
        <transition name="bounce">
          <b-row>
            <b-col>
              <div
                class="accordion accordion-solid accordion-toggle-plus"
                id="accordionExample3"
              >
                <div class="card">
                  <div class="card-header" id="headingOne3">
                    <div
                      class="card-title collapsed text-primary"
                      data-toggle="collapse"
                      data-target="#collapseOne3"
                      aria-expanded="false"
                    >
                      Busqueda avanzada
                    </div>
                  </div>
                  <div
                    id="collapseOne3"
                    class="collapse"
                    data-parent="#accordionExample3"
                    style=""
                  >
                    <div class="card-body">
                      <b-row>
                        <b-col>
                          Ordenes de trabajo - estados
                          <hr />
                          <div class="checkbox-inline">
                            <label class="checkbox checkbox-success">
                              <input
                                type="checkbox"
                                name="Checkboxes5"
                                @click="selectAll"
                                v-model="allSelected"
                              />
                              <span></span>Seleccionar todo</label
                            >
                          </div>
                          <hr />
                          <div
                            class="checkbox-list"
                            v-for="(item, idx) in statusList"
                            :key="idx"
                          >
                            <label :class="`checkbox ${item.class}`">
                              <input
                                type="checkbox"
                                name="Checkboxes1"
                                :value="item.id"
                                v-model="form.status"
                                @click="select"
                              />
                              <span></span> {{ item.text }}</label
                            >
                          </div>
                        </b-col>
                        <b-col>
                          Ordenes de trabajo - por sector
                          <hr />
                          <div class="checkbox-inline">
                            <label class="checkbox checkbox-success">
                              <input
                                type="checkbox"
                                name="Checkboxes5"
                                @click="selectAllSector"
                                v-model="allSelectedSector"
                              />
                              <span></span>Seleccionar todo</label
                            >
                          </div>
                          <hr />
                          <div
                            class="checkbox-list"
                            v-for="(item, idx) in configSector"
                            :key="idx + '__csector__'"
                          >
                            <label :class="`checkbox ${item.class}`">
                              <input
                                type="checkbox"
                                name="Checkboxes1"
                                :value="item.id"
                                v-model="form.sectores"
                                @click="selectSector"
                              />
                              <span></span> {{ item.text }}</label
                            >
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </transition>
        <b-col class="mt-5 d-flex justify-content-start align-items-center">
          <b-button
            @click="searchRegisters()"
            :disabled="isLoading"
            size="sm"
            variant="primary"
          >
            <i class="la la-search"></i>
            {{ isLoading ? "Consultando..." : "Consultar" }} 
          </b-button>
        </b-col>
      </div>
    </transition>
  </div>
</template>
<script>
import moment from "moment";
import DxCheckBox from "devextreme-vue/check-box";
export default {
  components: {
    DxCheckBox,
  },
  mounted() {
    this.getSectores();
    this.allSelected = true;
    this.allSelectedSector = true;
    this.checkedAll();
  },
  computed: {
    message() {
      return this.show
        ? "Ocultar panel de busqueda"
        : "Mostrar panel de busqueda";
    },
  },
  data() {
    return {
      show: true,
      isLoading: false,
      allSelectedSector: false,
      form: {
        sectores: [],
        status: [],
        date_init: moment().format("YYYY-MM-01"),
        date_end: moment().format("YYYY-MM-DD"),
      },
      allSelected: false,
      configSector: [],
      statusList: [
        {
          id: 2,
          text: "Asignadas",
          class: "checkbox-primary",
        },
        {
          id: 3,
          text: "Descargadas en el movil",
          class: "checkbox-dark",
        },
        {
          id: 4,
          text: "En ejecución",
          class: "checkbox-info",
        },
        {
          id: 5,
          text: "Ejecutadas",
          class: "checkbox-success",
        },
        {
          id: 6,
          text: "Certificadas",
          class: "checkbox-warning",
        },
        {
          id: 8,
          text: "Rechazadas",
          class: "checkbox-danger",
        },
      ],
    };
  },

  methods: {
    getSectores() {
      this.sectores = JSON.parse(sessionStorage.getItem("sectores"));

      this.sectores.forEach((element) => {
        this.configSector.push({
          id: element,
          text: element,
          class: "checkbox-primary",
        });
      });
    },
    searchRegisters() {
      this.isLoading = true;
      this.$emit("loadInformeExecute", this.form);
    },
    checkedAll() {
      for (let s in this.statusList) {
        this.form.status.push(this.statusList[s].id);
      }

      for (let s in this.configSector) {
        this.form.sectores.push(this.configSector[s].id);
      }
    },

    selectAll() {
      this.form.status = [];

      if (!this.allSelected) {
        for (let s in this.statusList) {
          this.form.status.push(this.statusList[s].id);
        }
      }
    },
    selectAllSector() {
      this.form.sectores = [];

      if (!this.allSelectedSector) {
        for (let s in this.configSector) {
          this.form.sectores.push(this.configSector[s].id);
        }
      }
    },

    select() {
      this.allSelected = false;
    },

    selectSector() {
      this.allSelectedSector = false;
    },
  },
};
</script>
